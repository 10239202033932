import { ApiResponse } from 'apisauce';

import { GatewayResponse, GatewayResponseStatus } from 'api/types/types';

import Utils from 'lib/Utils';
import { IOrder, IShipmentTrackingDetails, IsPartiallyPaidEnum } from 'entities/order';

import {
    ConvertOrderStatusToPaidParams,
    CreateOrderApiParams,
    CreateOrderCommentParams,
    CreateShipmentTrackingParams,
    DeleteClientPoParams,
    DeleteOrderApiParams,
    DeleteOrderAttachmentFolderParams,
    DeleteOrderAttachmentParams,
    DeleteOrderCommentParams,
    DeletePaymentProofParams,
    DeleteShipmentTrackingParams,
    EditOrderApiParams,
    ExportCommercialInvoiceParams,
    ExportDeliveryOrderParams,
    ExportOrderApiParams,
    GetAllShipmentTrackingParams,
    GetAllShipmentTrackingResponse,
    GetOrderAttachmentFolderNameParams,
    GetOrderCommentParams,
    GetOrderCommentResponse,
    GetOrderDetailsApiParams,
    GetOrderDetailsForPaymentParams,
    GetOrderReportParams,
    GetOrderReportResponse,
    GetPaymentLinkParams,
    GetShipmentTrackingDetailsParams,
    GetTimelineParams,
    GetTimelineResponse,
    IOrderGateway,
    SendPaymentLinkParams,
    setConfirmPaymentParams,
    UpdateOrderCommentParams,
    UpdateOrderStatusParams,
    UpdatePaymentTermsParams,
    UpdateProcessOrderParams,
    UpdateShipmentTrackingParams,
    UploadClientPoParams,
    UploadOrderAttachmentParams,
    UploadPaymentProofParams,
} from './OrderBase';

export default class OrderGateway extends IOrderGateway {
    async getOrderReport(params: GetOrderReportParams): GatewayResponse<GetOrderReportResponse> {
        const { authToken, ...rest } = params;

        const response: ApiResponse<GetOrderReportResponse> = await this.api.get(
            '/orders',
            { ...rest },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async getOrderDetails(params: GetOrderDetailsApiParams): GatewayResponse<IOrder> {
        const { authToken, orderId } = params;

        const response: ApiResponse<IOrder> = await this.api.get(
            `/orders/${orderId}`,
            {},
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async createOrder(params: CreateOrderApiParams): GatewayResponse<string> {
        const { authToken, ...rest } = params;

        const response: ApiResponse<string> = await this.api.post(
            '/orders/createOrder',
            { ...rest },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async updateOrder(params: EditOrderApiParams): GatewayResponse<void> {
        const { authToken, ...rest } = params;

        const response: ApiResponse<void> = await this.api.patch(
            '/orders/updateOrder',
            { ...rest },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async deleteOrder(params: DeleteOrderApiParams): GatewayResponse<void> {
        const { authToken, ...rest } = params;

        const response: ApiResponse<void> = await this.api.delete(
            '/orders/cancelOrder',
            { ...rest },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async exportOrder(params: ExportOrderApiParams): GatewayResponse<Blob> {
        const { authToken, id } = params;

        const response: ApiResponse<Blob> = await this.api.get(
            `/orders/invoicePDF/${id}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    Accept: 'application/pdf',
                },
                responseType: 'blob',
            },
        );

        return this.process(response);
    }

    async getTimeline(params: GetTimelineParams): GatewayResponse<GetTimelineResponse[]> {
        const { authToken, orderId } = params;

        const response: ApiResponse<GetTimelineResponse[]> = await this.api.get(
            `/orderAuditLogs/${orderId}`,
            {},
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async updateOrderStatus(params: UpdateOrderStatusParams): GatewayResponse<void> {
        const { authToken, ...rest } = params;

        const response: ApiResponse<void> = await this.api.put(
            '/orders/orderStatus',
            { ...rest },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async updateProcessOrder(params: UpdateProcessOrderParams): GatewayResponse<void> {
        const { authToken, ...rest } = params;

        const response: ApiResponse<void> = await this.api.put(
            '/orders/processOrder',
            { ...rest },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async updatePaymentTerms(params: UpdatePaymentTermsParams): GatewayResponse<void> {
        const { authToken, ...rest } = params;

        const response: ApiResponse<void> = await this.api.put(
            '/orders/paymentTerms',
            { ...rest },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async getOrderComment(params: GetOrderCommentParams): GatewayResponse<GetOrderCommentResponse> {
        const { authToken, ...rest } = params;

        const response: ApiResponse<GetOrderCommentResponse> = await this.api.get(
            '/orders/comments',
            { ...rest },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async createOrderComment(params: CreateOrderCommentParams): GatewayResponse<string> {
        const { authToken, ...commentDetails } = params;

        const response: ApiResponse<string> = await this.api.post(
            '/orders/comments',
            commentDetails,
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async deleteOrderComment(params: DeleteOrderCommentParams): GatewayResponse<void> {
        const { authToken, id } = params;

        const response: ApiResponse<void> = await this.api.delete(
            '/orders/comments',
            {},
            { ...Utils.getHeaders(authToken), data: { id } },
        );

        return this.process(response);
    }

    async updateOrderComment(params: UpdateOrderCommentParams): GatewayResponse<void> {
        const { authToken, ...rest } = params;

        const response: ApiResponse<void> = await this.api.patch(
            '/orders/comments',
            { ...rest },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async uploadClientPo(params: UploadClientPoParams): GatewayResponse<void> {
        const { authToken, orderId, receivedPurchaseOrder } = params;

        // create form data
        const form = new FormData();
        form.append('receivedPurchaseOrder', receivedPurchaseOrder as Blob);
        form.append('orderId', orderId);

        const response: ApiResponse<void> = await this.api.post(
            '/orders/receivedPurchaseOrder',
            form,
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async deleteClientPo(params: DeleteClientPoParams): GatewayResponse<void> {
        const { authToken, ...rest } = params;

        const response: ApiResponse<void> = await this.api.delete(
            '/orders/receivedPurchaseOrder',
            { ...rest },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async uploadPaymentProof(params: UploadPaymentProofParams): GatewayResponse<void> {
        const { authToken, orderId, paymentProof, isPartiallyPaid, paymentAmount } = params;

        // create form data
        const form = new FormData();
        form.append('isPartiallyPaid', isPartiallyPaid as unknown as string);
        form.append('paymentProof', paymentProof as Blob);
        form.append('orderId', orderId);
        if (isPartiallyPaid === IsPartiallyPaidEnum.true) {
            form.append('paymentAmount', paymentAmount as unknown as string);
        }

        const response: ApiResponse<void> = await this.api.post(
            '/orders/paymentProof',
            form,
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async deletePaymentProof(params: DeletePaymentProofParams): GatewayResponse<void> {
        const { authToken, ...rest } = params;

        const response: ApiResponse<void> = await this.api.delete(
            '/orders/paymentProof',
            { ...rest },
            { ...Utils.getHeaders(authToken) },
        );

        return this.process(response);
    }

    async uploadOrderAttachment(params: UploadOrderAttachmentParams): GatewayResponse<void> {
        const { authToken, orderId, folder, attachments } = params;

        // create form data
        const form = new FormData();
        form.append('orderId', orderId);
        form.append('folder', folder);
        attachments.forEach((attachment: File) => {
            form.append('attachments', attachment as Blob);
        });

        const response: ApiResponse<void> = await this.api.post(
            '/orders/attachments',
            form,
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async deleteOrderAttachment(params: DeleteOrderAttachmentParams): GatewayResponse<void> {
        const { authToken, ...rest } = params;

        const response: ApiResponse<void> = await this.api.delete(
            '/orders/attachments',
            { ...rest },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async deleteOrderAttachmentFolder(params: DeleteOrderAttachmentFolderParams): GatewayResponse<void> {
        const { authToken, ...rest } = params;

        const response: ApiResponse<void> = await this.api.delete(
            '/orders/attachmentFolder',
            { ...rest },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async getAllShipmentTracking(params: GetAllShipmentTrackingParams): GatewayResponse<GetAllShipmentTrackingResponse> {
        const { authToken, ...rest } = params;

        const response: ApiResponse<GetAllShipmentTrackingResponse> = await this.api.get(
            '/shipping',
            { ...rest },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async getShipmentTrackingDetails(params: GetShipmentTrackingDetailsParams): GatewayResponse<IShipmentTrackingDetails> {
        const { authToken, id } = params;

        const response: ApiResponse<IShipmentTrackingDetails> = await this.api.get(
            `/shipping/${id}`,
            {},
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async createShipmentTracking(params: CreateShipmentTrackingParams): GatewayResponse<void> {
        const { authToken, ...rest } = params;

        const response: ApiResponse<void> = await this.api.post(
            '/shipping',
            { ...rest },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async updateShipmentTracking(params: UpdateShipmentTrackingParams): GatewayResponse<void> {
        const { authToken, orderId, ...rest } = params;

        const response: ApiResponse<void> = await this.api.put(
            '/shipping',
            { ...rest },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async deleteShipmentTracking(params: DeleteShipmentTrackingParams): GatewayResponse<void> {
        const { authToken, id } = params;

        const response: ApiResponse<void> = await this.api.delete(
            '/shipping',
            { id },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async getOrderDetailsForPayment(params: GetOrderDetailsForPaymentParams): GatewayResponse<IOrder> {
        const { orderId } = params;

        const response: ApiResponse<IOrder> = await this.api.get(
            `/orders/paymentLink/${orderId}`,
            {},
            {},
        );

        return this.process(response);
    }

    async setConfirmPayment(params: setConfirmPaymentParams): GatewayResponse<void> {
        const { orderId } = params;

        const response: ApiResponse<void> = await this.api.post(
            '/payment/confirmPayment',
            { orderId },
            {},
        );

        return this.process(response);
    }

    async getPaymentLink(params: GetPaymentLinkParams): GatewayResponse<string> {
        const { authToken, orderId } = params;

        const response: ApiResponse<string> = await this.api.get(
            '/payment',
            { orderId },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async sendPaymentLink(params: SendPaymentLinkParams): GatewayResponse<void> {
        const { authToken, orderId } = params;

        const response: ApiResponse<void> = await this.api.get(
            '/payment/sendPaymentLink',
            { orderId },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async exportDeliveryOrder(params: ExportDeliveryOrderParams): GatewayResponse<Blob> {
        const { authToken, ...rest } = params;

        const response: ApiResponse<Blob> = await this.api.get(
            '/orders/shipmentTracking/deliveryOrder',
            { ...rest },
            {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    Accept: 'application/pdf',
                },
                responseType: 'blob',
            },
        );

        return this.process(response);
    }

    async exportCommercialInvoice(params: ExportCommercialInvoiceParams): GatewayResponse<Blob> {
        const { authToken, ...rest } = params;

        const response: ApiResponse<Blob> = await this.api.get(
            '/orders/shipmentTracking/commercialInvoice',
            { ...rest },
            {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    Accept: 'application/pdf',
                },
                responseType: 'blob',
            },
        );

        return this.process(response);
    }

    async convertOrderStatusToPaid(params: ConvertOrderStatusToPaidParams): GatewayResponse<void> {
        const { authToken, orderId } = params;

        const response: ApiResponse<void> = await this.api.put(
            '/orders/convertOrderToPaid',
            { orderId },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async convertOrderStatusToUnpaid(params: ConvertOrderStatusToPaidParams): GatewayResponse<void> {
        const { authToken, orderId } = params;

        const response: ApiResponse<void> = await this.api.put(
            '/orders/convertOrderToUnpaid',
            { orderId },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }

    async getOrderAttachmentFolderName(params: GetOrderAttachmentFolderNameParams): GatewayResponse<string[]> {
        const { authToken, orderId } = params;

        const response: ApiResponse<string[]> = await this.api.get(
            `/orders/getAttachmentFolder/${orderId}`,
            { },
            Utils.getHeaders(authToken),
        );

        return this.process(response);
    }
}
