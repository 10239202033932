import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { GetPurchaseOrdersApiResponse } from 'api/PurchaseOrdersBase';

import { IPurchaseOrder } from 'entities/purchaseOrders';

import {
    CreatePoUpdateParamsPayload,
    PurchaseOrderReduxState,
    SetPurchaseOrderFilters,
    UpdatePoEmailVariablesPayload,
} from './types';

const initialState: PurchaseOrderReduxState = {
    actions: {
        getPurchaseOrders: false,
        getPurchaseOrderDetails: false,
        updatePurchaseOrderDetailsStatus: false,

        createPurchaseOrder: false,
        updatePurchaseOrder: false,
        deletePurchaseOrder: false,

        downloadPurchaseOrder: false,
        sendPurchaseOrder: false,
        updateEmailVariables: false,
        viewPurchaseOrderEmail: false,
    },
    filters: {
        currentPage: 0,
        dateStart: dayjs().startOf('day').subtract(30, 'days').toISOString(),
        dateEnd: dayjs().endOf('day').toISOString(),

        searchQuery: undefined,
    },
    purchaseOrders: {
        index: 0,
        totalRecords: 0,
        maxIndex: 0,
        data: [],
    },
    ui: {
        confirmationDeleteModalIsOpen: false,
        sendPoModalIsOpen: false,
    },
    createOrEditPurchaseOrder: {
        companyAccountId: undefined,
        poDate: dayjs().startOf('day').toISOString(),
        supplierId: undefined,
        supplierName: undefined,
        supplierAddress: undefined,
        deliveryAddress: undefined,
        note: undefined,
        remark: undefined,
        products: [],
    },
    purchaseOrderDetails: null,
    error: {
        getPurchaseOrders: '',
        getPurchaseOrderDetails: '',
        updatePurchaseOrderDetailsStatus: '',

        createPurchaseOrder: '',
        updatePurchaseOrder: '',
        deletePurchaseOrder: '',

        downloadPurchaseOrder: '',
        sendPurchaseOrder: '',
        updateEmailVariables: '',
        viewPurchaseOrderEmail: '',
    },
};

const purchaseOrderSlice = createSlice({
    name: 'po',
    initialState,
    reducers: {
        poGetPurchaseOrdersAttempt: (state) => {
            state.actions.getPurchaseOrders = true;
            state.error.getPurchaseOrders = '';
        },
        poGetPurchaseOrdersSuccess: (state, action: PayloadAction<GetPurchaseOrdersApiResponse>) => {
            state.actions.getPurchaseOrders = false;
            state.purchaseOrders = action.payload;
        },
        poGetPurchaseOrdersFailure: (state, action: PayloadAction<string>) => {
            state.actions.getPurchaseOrders = false;
            state.error.getPurchaseOrders = action.payload || 'Something went wrong. Please try again.';
        },
        poGetPurchaseOrderDetailsAttempt: (state, _action: PayloadAction<{ id: string }>) => {
            state.actions.getPurchaseOrderDetails = true;
            state.error.getPurchaseOrderDetails = '';
        },
        poGetPurchaseOrderDetailsSuccess: (state, action: PayloadAction<IPurchaseOrder>) => {
            state.actions.getPurchaseOrderDetails = false;
            state.purchaseOrderDetails = action.payload;
        },
        poGetPurchaseOrderDetailsFailure: (state, action: PayloadAction<string>) => {
            state.actions.getPurchaseOrderDetails = false;
            state.error.getPurchaseOrderDetails = action.payload || 'Something went wrong. Please try again.';
        },
        poSetFilters: (state, action: SetPurchaseOrderFilters) => {
            const { currentPage, dateEnd, dateStart, status, searchQuery } = action.payload;

            if (currentPage !== undefined) state.filters.currentPage = currentPage;
            if (dateStart) {
                state.filters.dateStart = dateStart;
                state.filters.dateEnd = undefined;
            }
            if (dateEnd) {
                state.filters.dateEnd = dateEnd;
                state.filters.currentPage = 0;
            }
            if (searchQuery !== undefined) {
                if (!searchQuery.length) state.filters.searchQuery = undefined;
                else state.filters.searchQuery = searchQuery;
            }
            if (status !== undefined) {
                if (status > 0) {
                    state.filters.status = status;
                    state.filters.currentPage = 0;
                } else {
                    state.filters.status = undefined;
                    state.filters.currentPage = 0;
                }
            }
        },
        poResetPurchaseOrderFilters: (state) => {
            state.filters = initialState.filters;
        },

        poSetConfirmDeleteModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.ui.confirmationDeleteModalIsOpen = action.payload;
        },
        poSetSendPoModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.ui.sendPoModalIsOpen = action.payload;
        },
        poResetSendPo: (state) => {
            state.ui.sendPoModalIsOpen = false;
            state.actions.sendPurchaseOrder = false;
            state.error.sendPurchaseOrder = '';
        },

        poUpdateNewPoParams: (state, action: CreatePoUpdateParamsPayload) => {
            state.createOrEditPurchaseOrder = {
                ...state.createOrEditPurchaseOrder,
                ...action.payload,
            };
        },
        poResetNewPoParams: (state) => {
            state.actions.createPurchaseOrder = false;
            state.actions.updatePurchaseOrder = false;
            state.error.createPurchaseOrder = '';
            state.error.updatePurchaseOrder = '';
            state.createOrEditPurchaseOrder = initialState.createOrEditPurchaseOrder;
        },

        poCreatePurchaseOrderAttempt: (state) => {
            state.actions.createPurchaseOrder = true;
            state.error.createPurchaseOrder = '';
        },
        poCreatePurchaseOrderSuccess: (state) => {
            state.actions.createPurchaseOrder = false;
        },
        poCreatePurchaseOrderFailure: (state, action: PayloadAction<string>) => {
            state.actions.createPurchaseOrder = false;
            state.error.createPurchaseOrder = action.payload;
        },

        poEditPurchaseOrderAttempt: (state) => {
            state.actions.updatePurchaseOrder = true;
            state.error.updatePurchaseOrder = '';
        },
        poEditPurchaseOrderSuccess: (state) => {
            state.actions.updatePurchaseOrder = false;
        },
        poEditPurchaseOrderFailure: (state, action: PayloadAction<string>) => {
            state.actions.updatePurchaseOrder = false;
            state.error.updatePurchaseOrder = action.payload;
        },

        poDeletePurchaseOrderAttempt: (state, _action: PayloadAction<{ id: string }>) => {
            state.actions.deletePurchaseOrder = true;
            state.error.deletePurchaseOrder = '';
        },
        poDeletePurchaseOrderSuccess: (state) => {
            state.actions.deletePurchaseOrder = false;
        },
        poDeletePurchaseOrderFailure: (state, action: PayloadAction<string>) => {
            state.actions.deletePurchaseOrder = false;
            state.error.deletePurchaseOrder = action.payload;
        },

        poDownloadPurchaseOrderAttempt: (state, _action: PayloadAction<{ id: string }>) => {
            state.actions.downloadPurchaseOrder = true;
            state.error.downloadPurchaseOrder = '';
        },
        poDownloadPurchaseOrderSuccess: (state) => {
            state.actions.downloadPurchaseOrder = false;
        },
        poDownloadPurchaseOrderFailure: (state, action: PayloadAction<string>) => {
            state.actions.downloadPurchaseOrder = false;
            state.error.downloadPurchaseOrder = action.payload;
        },

        poSendPurchaseOrderAttempt: (state, _action: PayloadAction<{ id: string, emails: string }>) => {
            state.actions.sendPurchaseOrder = true;
            state.error.sendPurchaseOrder = '';
        },
        poSendPurchaseOrderSuccess: (state) => {
            state.actions.sendPurchaseOrder = false;
        },
        poSendPurchaseOrderFailure: (state, action: PayloadAction<string>) => {
            state.actions.sendPurchaseOrder = false;
            state.error.sendPurchaseOrder = action.payload;
        },
        poUpdateEmailVariablesAttempt: (state, _action: UpdatePoEmailVariablesPayload) => {
            state.actions.updateEmailVariables = true;
            state.error.updateEmailVariables = '';
        },
        poUpdateEmailVariablesSuccess: (state) => {
            state.actions.updateEmailVariables = false;
        },
        poUpdateEmailVariablesFailure: (state, action: PayloadAction<string>) => {
            state.actions.updateEmailVariables = false;
            state.error.updateEmailVariables = action.payload;
        },

        poViewPurchaseOrderEmailAttempt: (state, _action: PayloadAction<{ id: string }>) => {
            state.actions.viewPurchaseOrderEmail = true;
            state.error.viewPurchaseOrderEmail = '';
        },
        poViewPurchaseOrderEmailSuccess: (state) => {
            state.actions.viewPurchaseOrderEmail = false;
        },
        poViewPurchaseOrderEmailFailure: (state, action: PayloadAction<string>) => {
            state.actions.viewPurchaseOrderEmail = false;
            state.error.viewPurchaseOrderEmail = action.payload;
        },
    },
});

export default {
    actions: purchaseOrderSlice.actions,
    reducers: purchaseOrderSlice.reducer,
};
