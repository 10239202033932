import React, { FC, ParamHTMLAttributes } from 'react';

import styled from 'styled-components';

import { TextVariant } from './variants/TextVariant';

type TextProps = ParamHTMLAttributes<HTMLParagraphElement> & {
    variant?: TextVariant;
};

const Text: FC<TextProps> = (props: TextProps) => {
    const { variant = TextVariant.default, children, ...restOfProps } = props;

    if (variant === TextVariant.title) {
        return (
            <CustomH1
                {...restOfProps}
            >
                {children}
            </CustomH1>
        );
    }

    if (variant === TextVariant.h2) {
        return <CustomH2 {...restOfProps}>{children}</CustomH2>;
    }

    if (variant === TextVariant.h3) {
        return <CustomH3 {...restOfProps}>{children}</CustomH3>;
    }

    if (variant === TextVariant.h4) {
        return <CustomH4 {...restOfProps}>{children}</CustomH4>;
    }
    return (
        <CustomText
            variant={variant}
            {...restOfProps}
        >
            {children}
        </CustomText>
    );
};

const CustomH1 = styled.h1`
    font-size: ${props => props.theme.fontSize.lg};
    line-height: ${props => props.theme.lineHeight.lg};
    font-weight: ${props => props.theme.fontWeight.default};
    font-family: ${props => props.theme.fonts.primary};
    color: ${props => props.theme.fontColor.primary};
    letter-spacing: -0.025rem;

    @media (min-width: 1280px) {
        font-size: ${props => props.theme.fontSize.xl};
        line-height: ${props => props.theme.lineHeight.xl};
        letter-spacing: -0.075rem;
    }

    @media (min-width: 1520px) {
        font-size: ${props => props.theme.fontSize.xxl};
        line-height: ${props => props.theme.lineHeight.xxl};
        letter-spacing: -0.15rem;
    }
`;

const CustomH2 = styled.h2`
    color: ${props => props.theme.fontColor.primary};
    font-family: ${props => props.theme.fonts.primary};
    font-size: 26px;
    font-weight: 500;

    letter-spacing: -0.025rem;

    margin-bottom: 0px;

    @media (min-width: 1280px) {
        font-size: ${props => props.theme.fontSize.lg};
        line-height: ${props => props.theme.lineHeight.lg};
        letter-spacing: -0.075rem;
    }

    @media (min-width: 1520px) {
        font-size: ${props => props.theme.fontSize.xl};
        line-height: ${props => props.theme.lineHeight.xl};
        letter-spacing: -0.15rem;
    }
`;

const CustomH3 = styled.h3`
    font-family: ${props => props.theme.fonts.primary};
    font-size: 16px;
    font-weight: 700;
    color: ${props => props.theme.fontColor.primary};

    letter-spacing: -0.025rem;

    margin-bottom: 0px;
`;

const CustomH4 = styled.h4`
    font-family: ${props => props.theme.fonts.primary};
    font-size: 14px;
    font-weight: 500;
    color: ${props => props.theme.fontColor.secondary};

    letter-spacing: -0.025rem;

    margin-bottom: 0px;
`;

const CustomText = styled.p.withConfig({
    shouldForwardProp: prop => prop !== 'variant',
}) <{ variant?: TextVariant }>`
    all: unset;

    font-size: ${props => props.theme.fontSize.xs};
    line-height: ${props => props.theme.lineHeight.xs};
    font-weight: ${props => props.theme.fontWeight.default};
    font-family: ${props => props.theme.fonts.primary};
    color: ${props => props.theme.fontColor.tertiary};
    letter-spacing: -0.025rem;

    @media (min-width: 1280px) {
        font-size: ${props => props.theme.fontSize.sm};
        line-height: ${props => props.theme.lineHeight.sm};
    }

    @media (min-width: 1520px) {
        font-size: ${props => props.theme.fontSize.default};
        line-height: ${props => props.theme.lineHeight.default};
    }

    // error text
    ${props => props.variant === TextVariant.error && `
        color: ${props.theme.colors.error};
        font-size: ${props.theme.fontSize.default};
        font-weight: ${props.theme.fontWeight.bold};
    `}
`;

export default Text;
