import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';
import order from 'sagas/order';

import { IAddress } from 'entities/address';
import { IAdditionalDataForOrderAuditLog, IOrder, IOrderComment, IShipmentTrackingDetails, IsPartiallyPaidEnum, OrderPaymentMethodEnum, OrderStatusEnum } from 'entities/order';
import { IPrintMethodParams, TaxRateEnum } from 'entities/products';

export type GenericParams<T> = {
    authToken: string;
    params: T;
}

export type GetOrderReportParams = {
    authToken: string;
    index: number;

    searchQuery?: string;
    startDate?: string;
    endDate?: string;
    status?: OrderStatusEnum;
}

export type GetOrderReportResponse = {
    maxIndex: number;
    index: number;
    totalRecords: number;
    data: IOrder[];
}

export type GetOrderDetailsApiParams = {
    authToken: string;
    orderId: string;
}

export interface CreateOrderApiParams {
    authToken: string;
    companyAccountId: string;
    quotationId?: string;
    quotationNumber?: string;
    clientId: string;
    projectName: string;
    projectDueDate: string;
    shippingMethod: number;
    shippingAddress: IAddress;
    billingAddress: IAddress;
    paymentTerm: number;
    paymentDueDate: string;
    paymentMethod: OrderPaymentMethodEnum;
    note?: string;
    remark?: string;
    products: {
        productId: string;
        variantId: string;
        quantities: {
            productPriceId: string;
            size?: string;
            quantity: number;
            pricePerUnit: number;
        }[],
        printMethods: IPrintMethodParams[],
        finalQuantity: number;
        totalPricePerUnit: number;
        subtotal: number;
        discountPercent?: number;
        discountFixed?: number;
        finalProductPrice: number;
        discountAmountApplied: number;
        // weight?: number; // todo: might need this in future
        remark?: string;
    }[],
    customLineProducts: {
        productName: string;
        finalQuantity: number;
        finalProductPrice: number;
        totalPricePerUnit: number;
        subtotal: number;
        discountPercent?: number;
        discountFixed?: number;
        discountAmountApplied: number;
        weight?: number;
        remark?: string;
    }[];
    subtotal: number;
    discountAmountApplied?: number;
    discountPercent?: number;
    discountFixed?: number;
    shippingFee: number;
    tax: number;
    finalPrice: number;
    totalWeight: number;
    taxType: TaxRateEnum;
}

export interface EditOrderApiParams extends Partial<Omit<CreateOrderApiParams, 'authToken>'>> {
    authToken: string;
    id: string;
}

export interface DeleteOrderApiParams {
    authToken: string;
    id: string;
}

export type ExportOrderApiParams = {
    authToken: string;
    id: string;
}

export type GetTimelineParams = {
    authToken: string;
    orderId: string;
}

export type GetTimelineResponse = {
    id: string;
    orderId: string;
    action: number;
    data: IAdditionalDataForOrderAuditLog;
    createdBy: string;
    createdByName: string;
    createdAt: string;
}

export type UpdateOrderStatusParams = {
    authToken: string;
    id: string;
    status: OrderStatusEnum;
}

export type UpdateProcessOrderParams = {
    authToken: string;
    orderId: string;
    projectName: string;
    projectDueDate: string;
}
export type UpdatePaymentTermsParams = {
    authToken: string;
    orderId: string;
    paymentTerm: number;
    paymentDueDate: string;
}
export type GetOrderCommentParams = {
    authToken: string;
    orderId: string;

    index: number;
}

export type GetOrderCommentResponse = {
    index: number;
    maxIndex: number;
    totalRecords: number;
    data: IOrderComment[];
}

export type CreateOrderCommentParams = {
    authToken: string;
    orderId: string;
    text: string;
}

export type DeleteOrderCommentParams = {
    authToken: string;
    id: string;
}

export type UpdateOrderCommentParams = {
    authToken: string;
    id: string;
    text: string;
}

export type UploadClientPoParams = {
    authToken: string;
    orderId: string;
    receivedPurchaseOrder: File;
}

export type DeleteClientPoParams = {
    authToken: string;
    orderId: string;
    filePath: string;
}

export type UploadPaymentProofParams = {
    authToken: string;
    orderId: string;
    paymentProof: File;
    isPartiallyPaid: IsPartiallyPaidEnum;
    paymentAmount?: number;
}

export type DeletePaymentProofParams = {
    authToken: string;
    orderId: string;
    paymentProofId: string;
}

export type UploadOrderAttachmentParams = {
    authToken: string;
    orderId: string;
    folder: string;
    attachments: File[];
}

export type DeleteOrderAttachmentParams = {
    authToken: string;
    orderId: string;
    folder: string;
    filePath: string;
}

export type DeleteOrderAttachmentFolderParams = {
    authToken: string;
    orderId: string;
    folder: string;
}

export type GetAllShipmentTrackingParams = {
    authToken: string;
    orderId: string;
}

export type GetAllShipmentTrackingResponse = {
    id: string;
    trackingNo: string;
    trackingUrl: string;
    shippingVendor: string;
    sendOutDate: string;
    numberOfPackages: string;
    products: {
        productId: string;
        productName: string;
    }[];
    companyRegistrationNumber: string;
    companyNumber: string;
    companyEmail: string;
    companyAddress: IAddress;
}[];

export type GetShipmentTrackingDetailsParams = {
    authToken: string;
    id: string;
}

export type CreateShipmentTrackingParams = {
    authToken: string;
    orderId: string;
    companyRegistrationNumber: string,
    companyNumber: string,
    companyEmail: string,
    companyAddress: IAddress,
    trackingNo: string;
    trackingUrl: string;
    shippingVendor: string;
    sendOutDate: string;
    numberOfPackages: string;
    products: {
        productId: string;
        productName: string;
    }[];
    remarks?: string;
}

export type UpdateShipmentTrackingParams = CreateShipmentTrackingParams & {
    id: string;
}

export type DeleteShipmentTrackingParams = {
    authToken: string;
    id: string;
}
export type GetOrderDetailsForPaymentParams = {
    orderId: string;
}
export type setConfirmPaymentParams = {
    orderId: string;
}
export type GetPaymentLinkParams = {
    authToken: string;
    orderId: string;
}

export type SendPaymentLinkParams = {
    authToken: string;
    orderId: string;
}

export type ExportDeliveryOrderParams = {
    authToken: string;
    orderId: string;
    trackingId: string;
}
export type ExportCommercialInvoiceParams = {
    authToken: string;
    orderId: string;
    trackingId: string;
}

export type ConvertOrderStatusToPaidParams = {
    authToken: string;
    orderId: string;
}

export type GetOrderAttachmentFolderNameParams = {
    authToken: string;
    orderId: string;
}

export abstract class IOrderGateway extends Gateway {
    abstract getOrderReport(params: GetOrderReportParams): GatewayResponse<GetOrderReportResponse>;

    abstract getOrderDetails(params: GetOrderDetailsApiParams): GatewayResponse<IOrder>;

    abstract createOrder(params: CreateOrderApiParams): GatewayResponse<string>;

    abstract updateOrder(params: EditOrderApiParams): GatewayResponse<void>;

    abstract deleteOrder(params: DeleteOrderApiParams): GatewayResponse<void>;

    abstract exportOrder(params: ExportOrderApiParams): GatewayResponse<Blob>;

    abstract getTimeline(params: GetTimelineParams): GatewayResponse<GetTimelineResponse[]>;

    abstract updateOrderStatus(params: UpdateOrderStatusParams): GatewayResponse<void>;

    abstract updateProcessOrder(params: UpdateProcessOrderParams): GatewayResponse<void>;

    abstract updatePaymentTerms(params: UpdatePaymentTermsParams): GatewayResponse<void>;

    abstract getOrderComment(params: GetOrderCommentParams): GatewayResponse<GetOrderCommentResponse>;

    abstract createOrderComment(params: CreateOrderCommentParams): GatewayResponse<string>;

    abstract deleteOrderComment(params: DeleteOrderCommentParams): GatewayResponse<void>;

    abstract updateOrderComment(params: UpdateOrderCommentParams): GatewayResponse<void>;

    abstract uploadClientPo(params: UploadClientPoParams): GatewayResponse<void>;

    abstract deleteClientPo(params: DeleteClientPoParams): GatewayResponse<void>;

    abstract uploadPaymentProof(params: UploadPaymentProofParams): GatewayResponse<void>;

    abstract deletePaymentProof(params: DeletePaymentProofParams): GatewayResponse<void>;

    abstract uploadOrderAttachment(params: UploadOrderAttachmentParams): GatewayResponse<void>;

    abstract deleteOrderAttachment(params: DeleteOrderAttachmentParams): GatewayResponse<void>;

    abstract deleteOrderAttachmentFolder(params: DeleteOrderAttachmentFolderParams): GatewayResponse<void>;

    abstract getAllShipmentTracking(params: GetAllShipmentTrackingParams): GatewayResponse<GetAllShipmentTrackingResponse>;

    abstract getShipmentTrackingDetails(params: GetShipmentTrackingDetailsParams): GatewayResponse<IShipmentTrackingDetails>;

    abstract createShipmentTracking(params: CreateShipmentTrackingParams): GatewayResponse<void>;

    abstract updateShipmentTracking(params: UpdateShipmentTrackingParams): GatewayResponse<void>;

    abstract deleteShipmentTracking(params: DeleteShipmentTrackingParams): GatewayResponse<void>;

    abstract getOrderDetailsForPayment(params: GetOrderDetailsForPaymentParams): GatewayResponse<IOrder>;

    abstract setConfirmPayment(params: setConfirmPaymentParams): GatewayResponse<void>;

    abstract getPaymentLink(params: GetPaymentLinkParams): GatewayResponse<string>;

    abstract sendPaymentLink(params: SendPaymentLinkParams): GatewayResponse<void>;

    abstract exportDeliveryOrder(params: ExportDeliveryOrderParams): GatewayResponse<Blob>;

    abstract exportCommercialInvoice(params: ExportCommercialInvoiceParams): GatewayResponse<Blob>;

    abstract convertOrderStatusToPaid(params: ConvertOrderStatusToPaidParams): GatewayResponse<void>;

    abstract convertOrderStatusToUnpaid(params: ConvertOrderStatusToPaidParams): GatewayResponse<void>;

    abstract getOrderAttachmentFolderName(params: GetOrderAttachmentFolderNameParams): GatewayResponse<string[]>;
}
