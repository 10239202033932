import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { GetQuotationsApiResponse } from 'api/QuotationsBase';

import { IQuotation } from 'entities/quotations';

import {
    CreateQuotationUpdateParamsPayload,
    GetQuotationsPayload,
    QuotationsReduxState,
} from './types';

const initialState: QuotationsReduxState = {
    actions: {
        getQuotations: false,
        getQuotationDetails: false,
        createQuotation: false,
        updateQuotation: false,
        deleteQuotation: false,
        exportQuotation: false,
        convertQuotationToOrder: false,
    },
    filters: {
        currentPage: 0,
        dateStart: dayjs().startOf('day').subtract(30, 'days').toISOString(),
        dateEnd: dayjs().endOf('day').toISOString(),

        quoteStatus: undefined,
        searchQuery: undefined,
    },
    quotations: {
        index: 0,
        maxIndex: 0,
        totalRecords: 0,
        data: [],
    },
    createOrEditQuotation: {
        companyAccountId: undefined,
        quotationDate: dayjs().startOf('day').toISOString(),
        clientId: undefined,
        clientName: undefined,
        shippingMethod: undefined,
        shippingAddress: undefined,
        note: undefined,
        remark: undefined,
        products: [],
    },
    quotationDetails: null,
    ui: {
        confirmationDeleteModalIsOpen: false,
    },
    error: {
        getQuotations: '',
        getQuotationDetails: '',
        createQuotation: '',
        updateQuotation: '',
        deleteQuotation: '',
        exportQuotation: '',
        convertQuotationToOrder: '',
    },
};

const quotationsSlice = createSlice({
    name: 'quotations',
    initialState,
    reducers: {
        quoteGetQuotationsAttempt: (state) => {
            state.actions.getQuotations = true;
            state.error.getQuotations = '';
        },
        quoteGetQuotationsSuccess: (state, action: PayloadAction<GetQuotationsApiResponse>) => {
            state.actions.getQuotations = false;
            state.quotations = action.payload;
        },
        quoteGetQuotationsFailure: (state, action: PayloadAction<string>) => {
            state.actions.getQuotations = false;
            state.error.getQuotations = action.payload || 'Something went wrong. Please try again.';
        },
        quoteSetFilters: (state, action: GetQuotationsPayload) => {
            const { currentPage, dateEnd, dateStart, searchQuery, quoteStatus } = action.payload;

            if (currentPage !== undefined) state.filters.currentPage = currentPage;
            if (dateStart) {
                state.filters.dateStart = dateStart;
                state.filters.dateEnd = undefined;
            }
            if (dateEnd) {
                state.filters.dateEnd = dateEnd;
                state.filters.currentPage = 0;
            }
            if (searchQuery !== undefined) {
                if (!searchQuery.length) state.filters.searchQuery = undefined;
                else state.filters.searchQuery = searchQuery;
            }
            if (quoteStatus !== undefined) {
                if (quoteStatus > 0) {
                    state.filters.quoteStatus = quoteStatus;
                    state.filters.currentPage = 0;
                } else {
                    state.filters.quoteStatus = undefined;
                    state.filters.currentPage = 0;
                }
            }
        },

        quoteResetQuotationsFilters: (state) => {
            state.filters = initialState.filters;
        },
        quoteUpdateNewQuotationParams: (state, action: CreateQuotationUpdateParamsPayload) => {
            state.createOrEditQuotation = {
                ...state.createOrEditQuotation,
                ...action.payload,
            };
        },
        quoteResetQuotationDetails: (state) => {
            state.quotationDetails = null;
        },
        quoteResetNewQuotationParams: (state) => {
            state.actions.updateQuotation = false;
            state.actions.createQuotation = false;
            state.error.updateQuotation = '';
            state.error.createQuotation = '';
            state.createOrEditQuotation = initialState.createOrEditQuotation;
        },
        quoteCreateQuotationAttempt: (state) => {
            state.actions.createQuotation = true;
            state.error.createQuotation = '';
        },
        quoteCreateQuotationSuccess: (state) => {
            state.actions.createQuotation = false;
            state.error.createQuotation = '';
        },
        quoteCreateQuotationFailure: (state, action: PayloadAction<string>) => {
            state.actions.createQuotation = false;
            state.error.createQuotation = action.payload || 'Something went wrong. Please try again.';
        },
        quoteUpdateQuotationAttempt: (state) => {
            state.actions.updateQuotation = true;
            state.error.updateQuotation = '';
        },
        quoteUpdateQuotationSuccess: (state) => {
            state.actions.updateQuotation = false;
            state.error.updateQuotation = '';
        },
        quoteUpdateQuotationFailure: (state, action: PayloadAction<string>) => {
            state.actions.updateQuotation = false;
            state.error.updateQuotation = action.payload || 'Something went wrong. Please try again.';
        },
        quoteGetQuotationDetailsAttempt: (state, _action: PayloadAction<{ id: string }>) => {
            state.actions.getQuotationDetails = true;
            state.error.getQuotationDetails = '';
        },
        quoteGetQuotationDetailsSuccess: (state, action: PayloadAction<IQuotation>) => {
            state.actions.getQuotationDetails = false;
            state.quotationDetails = action.payload;
        },
        quoteGetQuotationDetailsFailure: (state, action: PayloadAction<string>) => {
            state.actions.getQuotationDetails = false;
            state.error.getQuotationDetails = action.payload || 'Something went wrong. Please try again.';
        },
        quoteDeleteQuotationAttempt: (state, _action: PayloadAction<{ id: string }>) => {
            state.actions.deleteQuotation = true;
            state.error.deleteQuotation = '';
        },
        quoteDeleteQuotationSuccess: (state) => {
            state.actions.deleteQuotation = false;
            state.error.deleteQuotation = '';
        },
        quoteDeleteQuotationFailure: (state, action: PayloadAction<string>) => {
            state.actions.deleteQuotation = false;
            state.error.deleteQuotation = action.payload || 'Something went wrong. Please try again.';
        },
        quoteExportQuotationAttempt: (state, _action: PayloadAction<{ id: string }>) => {
            state.actions.exportQuotation = true;
            state.error.exportQuotation = '';
        },
        quoteExportQuotationSuccess: (state) => {
            state.actions.exportQuotation = false;
            state.error.exportQuotation = '';
        },
        quoteExportQuotationFailure: (state, action: PayloadAction<string>) => {
            state.actions.exportQuotation = false;
            state.error.exportQuotation = action.payload || 'Something went wrong. Please try again.';
        },
        quoteConvertQuotationToOrderAttempt: (state, _action: PayloadAction<{ id: string }>) => {
            state.actions.convertQuotationToOrder = true;
            state.error.convertQuotationToOrder = '';
        },
        quoteConvertQuotationToOrderSuccess: (state) => {
            state.actions.convertQuotationToOrder = false;
            state.error.convertQuotationToOrder = '';
        },
        quoteConvertQuotationToOrderFailure: (state, action: PayloadAction<string>) => {
            state.actions.convertQuotationToOrder = false;
            state.error.convertQuotationToOrder = action.payload || 'Something went wrong. Please try again.';
        },
        quoteSetConfirmDeleteModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.ui.confirmationDeleteModalIsOpen = action.payload;
        },
    },
});

export type QuotationState = typeof initialState;

export default {
    actions: quotationsSlice.actions,
    reducers: quotationsSlice.reducer,
};
