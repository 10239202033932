import React, { useEffect } from 'react';

import styled from 'styled-components';

import LoadingIndicator from 'components/atoms/LoadingIndicator';
import Text from 'components/atoms/Text';
import { TextVariant } from 'components/atoms/variants/TextVariant';
import ProductViewer from 'components/molecules/ProductViewer';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import AuthSelectors from 'redux/slices/auth/Selectors';
import CompanySelectors from 'redux/slices/company/Selectors';

import Utils from 'lib/Utils';
import { AuthRoleEnum } from 'entities/auth';
import { IOrder } from 'entities/order';
import { IProductDetails, TaxRateEnum } from 'entities/products';
import { CurrencyEnum } from 'entities/supplier';

interface ComponentProps {
    orderDetails: IOrder | null;
    loading?: boolean;
    error?: string;
}

const ProductListTable: React.FC<ComponentProps> = (props: ComponentProps) => {
    const { orderDetails, loading, error } = props;

    const {
        formatVariantLabel,
        formatSizeLabel,
        formatPrintMethodLabel,
    } = Utils.Formatter;

    const productTableHeaders = [
        { header: 'Name' },
        { header: 'Quantity' },
        { header: 'Price Per Unit' },
        { header: 'Price After Discount' },
        { header: 'Discount Total' },
        { header: 'Total' },
    ];

    const dispatch = useAppDispatch();
    const userInfo = useAppSelector(AuthSelectors.getUserInfo);
    const companyDetails = useAppSelector(CompanySelectors.getCompanyDetails);

    useEffect(() => {
        if (orderDetails?.companyAccountId && userInfo?.role !== AuthRoleEnum.Supplier) {
            dispatch(Actions.companyGetCompanyDetailsAttempt({ id: orderDetails?.companyAccountId ?? '' }));
            if (orderDetails?.taxType) {
                if (orderDetails.taxType === TaxRateEnum.GstTaxRate) {
                    dispatch(Actions.productSetIsTaxApplied(true));
                } else {
                    dispatch(Actions.productSetIsTaxApplied(false));
                }
            }
        }
    }, [orderDetails?.companyAccountId]);

    const NewSelectedProducts = (): IProductDetails[] => {
        if (!orderDetails?.products) return [];
        const selectedProducts = orderDetails?.products.map(product => {
            return {
                productId: product.productId,
                variantId: product.variantId,
                name: product.productName ?? 'Product Name Here',
                variant: {
                    type: product.productVariant?.type,
                    fit: product.productVariant?.fit,
                    sleeve: product.productVariant?.sleeve,
                    style: product.productVariant?.style,
                    color: product.productVariant?.color,
                } || {},
                variantText: formatVariantLabel(product.productVariant ? product.productVariant : null),
                sizeText: formatSizeLabel(product.quantities),
                printMethodText: product.printMethods.map((method) => {
                    return formatPrintMethodLabel({
                        side: method.side,
                        printMethod: method.printMethod ?? '',
                        block: method.block ?? '',
                        colorCount: method.colorCount ?? '',
                    });
                }),
                totalQuantity: product.finalQuantity,
                quantities: product.quantities.map(item => ({
                    id: item.productPriceId,
                    size: item.size,
                    quantity: item.quantity,
                    pricePerUnit: item.pricePerUnit,
                })),
                discountPercent: product.discountPercent ?? undefined,
                discountFixed: product.discountFixed ?? undefined,
                printMethods: product.printMethods,
                price: product.totalPricePerUnit?.toFixed(2) || '0.00',
                total: product.finalProductPrice,
                disableQuantityEdit: true,
            };
        });

        const customLineProducts = orderDetails?.customLineProducts.map(product => {
            return {
                productId: '',
                variantId: '',
                name: product.productName ?? 'Product Name Here',
                totalQuantity: product.finalQuantity,
                price: product.totalPricePerUnit?.toFixed(2) || '0.00',
                total: product.finalProductPrice,
                discountPercent: product.discountPercent ?? undefined,
                discountFixed: product.discountFixed ?? undefined,
                disableQuantityEdit: true,
            };
        });

        const combinedSelectedProduct = [...selectedProducts, ...customLineProducts];
        return combinedSelectedProduct;
    };

    if (loading) {
        return (
            <ProductTableContainer>
                <LoadingIndicator />
            </ProductTableContainer>
        );
    }
    if (error) {
        return (
            <ProductTableContainer>
                <Text variant={TextVariant.error}>{error}</Text>
            </ProductTableContainer>
        );
    }

    return (
        <ProductTableContainer>
            <ProductViewer
                isOrder
                tableHeaders={productTableHeaders}
                currency={orderDetails?.currency ?? CurrencyEnum.MalaysianRinggit}
                viewData={{
                    selectedProducts: NewSelectedProducts(),
                    discountPercent: orderDetails?.discountPercent?.toString(),
                    discountFixed: orderDetails?.discountFixed?.toFixed(2),
                    shippingCost: orderDetails?.shippingFee.toFixed(2) ?? '0.00',
                    taxPrice: orderDetails?.tax.toFixed(2) ?? '0.00',
                }}
                taxPercent={orderDetails?.tax === 0 ? 0 : companyDetails?.tax}
            />
        </ProductTableContainer>
    );
};

const ProductTableContainer = styled.div`
    display: flex;
    padding: 1.5rem 2rem;

    border-radius: 15px;

    min-height: 400px;

    background-color: white;
`;

export default ProductListTable;
