import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import Styles from 'containers/orders/styles/OrderStyles';
import PageHeader from 'components/atoms/PageHeader';
import Paginator from 'components/atoms/Paginator';
import Table, { IActionsArray, TableContainer } from 'components/atoms/Table';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import AdminLayout from 'components/Layout/AdminLayout';
import ConfirmationModal from 'components/molecules/ConfirmationModal';
import TableFilters from 'components/molecules/TableFilters';

import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import AuthSelectors from 'redux/slices/auth/Selectors';
import OrderSelectors from 'redux/slices/order/Selectors';

import NavActions from 'lib/NavActions';
import Utils from 'lib/Utils';
import { AuthRoleEnum } from 'entities/auth';
import { OrderStatusEnum } from 'entities/order';

import { ReactComponent as ConfirmationIcon } from 'assets/icons/confirmation.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';

const OrdersScreen = (): JSX.Element => {
    const dispatch = useAppDispatch();

    const userInfo = useAppSelector(AuthSelectors.getUserInfo);
    const orders = useAppSelector(OrderSelectors.getOrderReportData);
    const ordersLoading = useAppSelector(OrderSelectors.getOrderReportAttempting);
    const ordersError = useAppSelector(OrderSelectors.getOrderReportError);

    const filters = useAppSelector(OrderSelectors.getOrdersFilters);
    const confirmationModalIsOpen = useAppSelector(OrderSelectors.deleteConfirmationModalIsOpen);
    const deleteOrderLoading = useAppSelector(OrderSelectors.deleteOrderAttempting);
    const deleteOrderError = useAppSelector(OrderSelectors.deleteOrderError);

    const { maxIndex, data: ordersList, totalRecords } = orders;
    const { currentPage, searchQuery, dateStart, dateEnd, orderStatus } = filters;

    const [idToDelete, setIdToDelete] = useState<string | null>(null);
    const [orderNumberToDelete, setOrderNumberToDelete] = useState<string | null>(null);

    const getOrders = () => {
        dispatch(Actions.orderGetOrderReportAttempt());
    };
    useEffect(() => {
        dispatch(Actions.orderResetOrdersFilters());
    }, []);

    useEffect(() => {
        if (currentPage <= maxIndex && dateStart && dateEnd) {
            getOrders();
        }
    }, [currentPage, orderStatus, dateStart, dateEnd]);

    const onPaginatorPressed = (page: number) => {
        dispatch(Actions.orderSetFilters({ currentPage: page }));
    };

    const onStatusFiltered = (option: number) => {
        dispatch(Actions.orderSetFilters({
            orderStatus: option,
        }));
        getOrders();
    };

    const onDateFilterSelected = (selectedStartDate: Date, selectedEndDate: Date) => {
        dispatch(Actions.orderSetFilters({
            dateStart: dayjs(selectedStartDate).startOf('day').toISOString(),
            dateEnd: selectedEndDate ? dayjs(selectedEndDate).endOf('day').toISOString() : undefined,
        }));
    };

    const onSearchQueryChanged = (val: string) => {
        dispatch(Actions.orderSetFilters({
            searchQuery: val,
        }));
    };

    const onDeleteOrderCancelled = () => {
        setIdToDelete(null);
        setOrderNumberToDelete(null);

        dispatch(Actions.orderSetConfirmDeleteModalIsOpen(false));
    };

    const onDeleteOrderConfirmed = () => {
        if (idToDelete) {
            dispatch(Actions.orderDeleteOrderAttempt({ id: idToDelete }));
        } else {
            toast.error('Please select an order to delete');
        }
    };

    const options = [
        { label: 'All Statuses', value: 0 },
        { label: 'Pending', value: OrderStatusEnum.Pending },
        { label: 'Packing', value: OrderStatusEnum.Packing },
        { label: 'Shipped', value: OrderStatusEnum.Shipped },
        { label: 'Delivered', value: OrderStatusEnum.Delivered },
        { label: 'In Production', value: OrderStatusEnum.InProduction },
    ];

    const ordersHeaders = [
        { header: 'Order No', field: 'id' },
        { header: 'Date', field: 'dateOfProject' },
        { header: 'Customer', field: 'clientName' },
        { header: 'Total', field: 'total' },
        { header: 'Project', field: 'projectName' },
        { header: 'Consultant', field: 'consultantName' },
        { header: 'Status', field: 'status' },
        { header: 'Actions', field: 'action' },
    ];

    const tableData = ordersList.map((item) => ({
        id: item.id,
        onClick: () => NavActions.navToOrderDetails(item.id),
        cellData: {
            id: item.orderNo,
            dateOfProject: dayjs(item.createdAt).format('DD/MM/YYYY'),
            clientName: item.clientName,
            total: item.finalPrice && `${Utils.Formatter.formatCurrency(item.currency)}${Utils.Formatter.formatPrice(item.finalPrice)}`,
            consultantName: item.personInCharge,
            projectName: item.projectName,
            status: OrderStatusEnum[item.status],
        },
    }));

    const tableActions: IActionsArray[] = [
        {
            id: 'delete',
            icon: <DeleteIcon />,
            onClick: (orderId: string) => {
                const selectedOrder = orders.data.find((item) => item.id === orderId);
                if (selectedOrder) {
                    setIdToDelete(selectedOrder.id);
                    setOrderNumberToDelete(selectedOrder.orderNo);
                }

                dispatch(Actions.orderSetConfirmDeleteModalIsOpen(true));
            },
        }];

    return (
        <AdminLayout>
            <PageHeader subtitle={`${totalRecords} total order(s)`}>
                Orders
            </PageHeader>

            <TableFilters
                statusFilterEnabled
                availableStatuses={options}
                onStatusSelected={onStatusFiltered}
                dateFilterEnabled
                selectedFromDate={dateStart ? dayjs(dateStart).toDate() : undefined}
                selectedToDate={dateEnd ? dayjs(dateEnd).toDate() : undefined}
                onDatesSelected={onDateFilterSelected}
                searchFilterEnabled
                searchValue={searchQuery}
                onSearchChanged={onSearchQueryChanged}
                onSearch={getOrders}
                addNewButtonEnabled
                addNewLabel='Add new order'
                onAddNew={() => NavActions.navToAddNewOrder()}
                addButtonVariant={ButtonVariant.secondary}
                disableAddNew={userInfo?.role === AuthRoleEnum.Supplier}
            />
            <Table
                headerArrays={ordersHeaders}
                dataArray={tableData}
                actions={tableActions}
                style={{ marginTop: 32, marginBottom: 32 }}
                loading={ordersLoading}
                error={ordersError}
                disableActions={userInfo?.role === AuthRoleEnum.Supplier}
            />
            <PaginationContainer>
                <Paginator
                    currentPage={currentPage}
                    onPagePressed={onPaginatorPressed}
                    maxPages={maxIndex}
                    disabled={ordersLoading}
                />
            </PaginationContainer>
            <ConfirmationModal
                isOpen={confirmationModalIsOpen}
                icon={<ConfirmationIcon />}
                title={`Delete "${orderNumberToDelete}"?`}
                text='Please confirm deleting this order.'
                onCancel={onDeleteOrderCancelled}
                onConfirm={onDeleteOrderConfirmed}
                cancelText='Cancel'
                confirmText='Confirm'
                confirmButtonVariant={ButtonVariant.warning}
                loading={deleteOrderLoading}
                error={deleteOrderError}
            />
        </AdminLayout>
    );
};

const PaginationContainer = styled.div`
    width: 100%;

    display: flex;
    justify-content: center;
`;

export default OrdersScreen;
