import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import AddressDisplay from 'components/atoms/AddressDisplay';
import Button from 'components/atoms/Button';
import Checkbox from 'components/atoms/Checkbox';
import DateFilterButton from 'components/atoms/DateFilter';
import DropdownButton from 'components/atoms/DropdownButton';
import Input from 'components/atoms/Input';
import PageHeader from 'components/atoms/PageHeader';
import Table from 'components/atoms/Table';
import Text from 'components/atoms/Text';
import { ButtonVariant } from 'components/atoms/variants/ButtonVariant';
import { TextVariant } from 'components/atoms/variants/TextVariant';
import AdminLayout from 'components/Layout/AdminLayout';

import { CreateShipmentTrackingParams } from 'api/OrderBase';
import Actions from 'redux/Actions';
import { useAppDispatch, useAppSelector } from 'redux/Hooks';
import CompanySelectors from 'redux/slices/company/Selectors';
import Selectors from 'redux/slices/customers/Selectors';
import OrderSelectors from 'redux/slices/order/Selectors';
import { CreateShipmentTrackingPayload } from 'redux/slices/order/types';

import { IAddress } from 'entities/address';
import { IOrderProduct } from 'entities/order';
import { IQuotationProductQuantity, QuotationShippingMethodEnum } from 'entities/quotations';
import ShippingMethodsLov from 'lov/ShippingMethodsLov';

const AddShipmentTracking = (): JSX.Element => {
    const params = useParams();
    const { orderId, trackingId } = params;

    const dispatch = useAppDispatch();
    const orderDetails = useAppSelector(OrderSelectors.getOrderDetailsData);
    const customerDetails = useAppSelector(Selectors.getCustomerDetails);
    const companyDetails = useAppSelector(CompanySelectors.getCompanyDetails);
    const shipmentTrackingDetails = useAppSelector(OrderSelectors.orderGetShipmentTrackingDetailsData);

    const createShipmentTrackingLoading = useAppSelector(OrderSelectors.orderCreateShipmentTrackingAttempting);
    const createShipmentTrackingError = useAppSelector(OrderSelectors.orderCreateShipmentTrackingError);

    const updateShipmentTrackingLoading = useAppSelector(OrderSelectors.orderUpdateShipmentTrackingAttempting);
    const updateShipmentTrackingError = useAppSelector(OrderSelectors.orderUpdateShipmentTrackingError);

    const [companyName, setCompanyName] = useState<string>('');
    const [companyNumber, setCompanyNumber] = useState<string>('');
    const [companyContact, setCompanyContact] = useState<string>('');
    const [companyEmail, setCompanyEmail] = useState<string>('');
    const [companyAddress, setCompanyAddress] = useState<IAddress>();

    const [shippingMethod, setShippingMethod] = useState<QuotationShippingMethodEnum>();
    const [trackingNumber, setTrackingNumber] = useState('');
    const [trackingUrl, setTrackingUrl] = useState('');
    const [shippingVendor, setShippingVendor] = useState('');
    const [shippingDate, setShippingDate] = useState(dayjs().format('YYYY-MM-DD'));
    const [carton, setCarton] = useState('');
    const [remarks, setRemarks] = useState('');

    const [checkedProduct, setCheckedProduct] = useState<IOrderProduct[]>([]);
    const [error, setError] = useState<string>('');

    useEffect(() => {
        if (orderId) {
            dispatch(Actions.orderGetOrderDetailsAttempt({ id: orderId }));
        }
        if (trackingId) {
            dispatch(Actions.orderGetShipmentTrackingDetailsAttempt({ id: trackingId }));
        }

        return () => {
            dispatch(Actions.orderResetShipmentTrackingDetails());
        };
    }, []);

    useEffect(() => {
        if (orderDetails) {
            dispatch(Actions.companyGetCompanyDetailsAttempt({ id: orderDetails.companyAccountId }));
            dispatch(Actions.customersGetCustomerDetailsAttempt({ id: orderDetails.clientId }));
            setShippingMethod(orderDetails.shippingMethod);
            setCheckedProduct(orderDetails.products);
        }
    }, [orderDetails]);

    useEffect(() => {
        if (!shipmentTrackingDetails && companyDetails) {
            setCompanyName(companyDetails.name);
            setCompanyNumber(companyDetails.registrationNumber ?? '');
            setCompanyContact(companyDetails.number ?? '');
            setCompanyEmail(companyDetails.email ?? '');
            setCompanyAddress(companyDetails.address);
        }
    }, [companyDetails]);

    useEffect(() => {
        if (shipmentTrackingDetails) {
            setCompanyNumber(shipmentTrackingDetails.companyRegistrationNumber);
            setCompanyContact(shipmentTrackingDetails.companyNumber);
            setCompanyEmail(shipmentTrackingDetails.companyEmail);
            setCompanyAddress(shipmentTrackingDetails.companyAddress);
            setTrackingNumber(shipmentTrackingDetails.trackingNo);
            setTrackingUrl(shipmentTrackingDetails.trackingUrl);
            setShippingVendor(shipmentTrackingDetails.shippingVendor);
            setShippingDate(shipmentTrackingDetails.sendOutDate);
            setCarton(shipmentTrackingDetails.numberOfPackages);
            setRemarks(shipmentTrackingDetails.remarks ?? '');
        }
    }, [shipmentTrackingDetails]);

    const massagedAddress = (address?: IAddress) => {
        if (!address) return '';
        if (address.street2) {
            return `${address.street1}, ${address.street2}, ${address.city}, ${address.state}, ${address.country}, ${address.postcode}`;
        }

        return `${address.street1}, ${address.city}, ${address.state}, ${address.country}, ${address.postcode}`;
    };

    const productTableHeaders = [
        {
            key: 'product',
            header: <Checkbox
                label='Products'
                textStyle={{ fontWeight: 'bold' }}
                containerStyle={{ gap: '2rem' }}
                isChecked={checkedProduct.length === orderDetails?.products.length}
                onChange={() => {
                    if (checkedProduct.length === orderDetails?.products.length) {
                        setCheckedProduct([]);
                    } else {
                        setCheckedProduct(orderDetails?.products as IOrderProduct[]);
                    }
                }}
            />,
        },
        { key: 'quantity', header: 'Quantity' },
    ];

    const customerInputDetails = [
        { header: 'Customer Name', value: customerDetails?.contactPerson },
        { header: 'Customer Contact', value: customerDetails?.contact },
        { header: 'Customer Email', value: customerDetails?.email },
        { header: 'Customer Company Name', value: customerDetails?.companyName },
        { header: 'Shipping Address', value: massagedAddress(orderDetails?.shippingAddress), textarea: true },
        { header: 'Billing Address', value: massagedAddress(orderDetails?.billingAddress), textarea: true },
    ];

    const companyInputDetails = [
        { header: 'Company Name', value: companyDetails?.name },
        { header: 'Company Number', value: companyDetails?.registrationNumber },
        { header: 'Company Contact', value: companyDetails?.number },
        { header: 'Company Email', value: companyDetails?.email },
        { header: 'Company Address', value: massagedAddress(companyDetails?.address), textarea: true },
    ];

    const handleCheckboxChange = (product: IOrderProduct) => {
        if (checkedProduct.includes(product)) {
            setCheckedProduct(checkedProduct.filter((p) => p !== product));
        } else {
            setCheckedProduct([...checkedProduct, product]);
        }
    };

    const onAddShipmentTracking = () => {
        if (!companyNumber || !companyContact || !companyEmail || !companyAddress) {
            setError('Please fill in all required fields');
            return;
        }

        if (!companyAddress.state) {
            setError('Please fill in all required fields');
            return;
        }

        if (!trackingNumber || !trackingUrl || !shippingVendor || !carton) {
            setError('Please fill in all required fields');
            return;
        }

        if (checkedProduct.length === 0) {
            setError('Please select at least one product');
            return;
        }

        const shippingData: Omit<CreateShipmentTrackingParams, 'authToken'> = {
            orderId: orderId as string,
            companyRegistrationNumber: companyNumber,
            companyNumber: companyContact,
            companyEmail,
            companyAddress,
            trackingNo: trackingNumber,
            trackingUrl,
            shippingVendor,
            sendOutDate: shippingDate,
            numberOfPackages: carton,
            remarks,
            products: checkedProduct.map((product) => {
                return {
                    productId: product.productId,
                    productName: product.productName ?? 'Product Name Here',
                };
            }),
        };

        setError('');
        if (trackingId) {
            dispatch(Actions.orderUpdateShipmentTrackingAttempt({ ...shippingData, id: trackingId }));
            return;
        }
        dispatch(Actions.orderCreateShipmentTrackingAttempt(shippingData));
    };

    return (
        <AdminLayout>
            <PageHeader withBackButton>
                <PageTitle>
                    {`${trackingId ? 'Edit' : 'Add'} Shipment Tracking for ${orderDetails?.orderNo ?? 'Order'}`}
                </PageTitle>
            </PageHeader>
            <Container>
                <FormContainer>
                    <div>
                        {customerInputDetails.map((input) => (
                            <Input
                                key={input.header}
                                header={input.header}
                                value={input.value}
                                variant={input.textarea ? 'textarea' : 'default'}
                                disabled
                            />
                        ))}
                    </div>
                    <div>
                        <Input
                            header='Company Name'
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            error={!!error && !companyName}
                            disabled
                        />
                        <Input
                            required
                            header='Company Number'
                            value={companyNumber}
                            onChange={(e) => setCompanyNumber(e.target.value)}
                            error={!!error && !companyNumber}
                        />
                        <Input
                            required
                            header='Company Contact'
                            value={companyContact}
                            onChange={(e) => setCompanyContact(e.target.value)}
                            error={!!error && !companyContact}
                        />
                        <Input
                            required
                            header='Company Email'
                            value={companyEmail}
                            onChange={(e) => setCompanyEmail(e.target.value)}
                            error={!!error && !companyEmail}
                        />
                        <AddressDisplay
                            required
                            editable
                            label='Company Address'
                            address={companyAddress}
                            onChange={(companyAddressValue) => setCompanyAddress(companyAddressValue)}
                            error={!!error || !companyAddress}
                        />
                    </div>
                    <div>
                        <div style={{ flex: 1 }}>
                            <DropdownButton
                                label='Shipping method'
                                value={shippingMethod}
                                placeholder='Select shipping method'
                                options={ShippingMethodsLov}
                                onSelect={(value) => setShippingMethod(value as number)}
                            />
                        </div>
                        <Input
                            required
                            header='Tracking Number'
                            value={trackingNumber}
                            onChange={(e) => setTrackingNumber(e.target.value)}
                            error={!!error && !trackingNumber}
                        />
                        <Input
                            required
                            header='Tracking URL'
                            value={trackingUrl}
                            onChange={(e) => setTrackingUrl(e.target.value)}
                            error={!!error && !trackingUrl}
                        />
                        <Input
                            required
                            header='Shipping Vendor'
                            value={shippingVendor}
                            onChange={(e) => setShippingVendor(e.target.value)}
                            error={!!error && !shippingVendor}
                        />
                        <div style={{ flex: 1 }}>
                            <DateFilterButton
                                label='Shipping Date'
                                onChangeDate={(date: Date) => setShippingDate(dayjs(date).startOf('day').toISOString())}
                                dateFrom={dayjs(shippingDate).toDate()}
                            />
                        </div>
                        <Input
                            required
                            header='Carton'
                            value={carton}
                            onChange={(e) => setCarton(e.target.value)}
                            error={!!error && !carton}
                        />
                        <Input
                            variant='textarea'
                            header='Remarks'
                            value={remarks}
                            onChange={(e) => setRemarks(e.target.value)}
                        />
                    </div>
                </FormContainer>
                <BottomContainer>
                    <ProductTableContainer>
                        <thead>
                            <tr>
                                {productTableHeaders.map((header) => (
                                    <th key={header.key}>{header.header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {orderDetails?.products.map((product) => (
                                <tr key={product.productName}>
                                    <td>
                                        <Checkbox
                                            label={product.productName}
                                            containerStyle={{ gap: '2rem' }}
                                            isChecked={checkedProduct.includes(product)}
                                            onChange={() => handleCheckboxChange(product)}
                                        />
                                    </td>
                                    <td>
                                        {product.finalQuantity}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </ProductTableContainer>
                </BottomContainer>
                {error && (
                    <ErrorContainer>
                        <Text variant={TextVariant.error}>{error}</Text>
                    </ErrorContainer>
                )}
                <ButtonContainer>
                    <Button
                        label='Save'
                        style={{ width: '100px' }}
                        variant={ButtonVariant.secondary}
                        onClick={() => onAddShipmentTracking()}
                        loading={createShipmentTrackingLoading || updateShipmentTrackingLoading}
                    />
                </ButtonContainer>
            </Container>
        </AdminLayout>
    );
};

const PageTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

const FormContainer = styled.div`
    background-color: white;
    border-radius: 15px;

    margin-top: 12px;

    padding: 24px 30px;

    display: flex;
    flex-direction: row;

    gap: 18px;

    min-height: 600px;

    > div {
        flex: 1 0 30%;
        display: flex;
        flex-direction: column;
        gap: 18px;
    }
`;

const BottomContainer = styled.div`
    display: grid;
    width: 100%;

    padding: 24px 30px;

    border-radius: 15px;

    justify-content: space-between;
    background-color: white;
`;

const ErrorContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
`;

const ProductTableContainer = styled.table`
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;

    th, td {
        padding: 1rem;
        border: 1px solid #42564E33;
    }
`;

export default AddShipmentTracking;
